<template>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">
            Welcome User !
          </h2>
          <b-card-text class="mb-2">
            <span>We are delighted that you have joined our family. </span>
          </b-card-text>

          <!-- form -->
         
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content">

      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in knowledgeBase"
          :key="item.id"
          md="4"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer"
            :img-src="item.img"
            :img-alt="item.img.slice(5)"
            img-top
            @click="doShit(item.id)"

            
          >
            <h4>{{ item.title }}</h4>
            <b-card-text class="mt-1">
              {{ item.desc }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend,
} from 'bootstrap-vue'
import { getAuth } from "firebase/auth";
import { db } from '@/firebase/index'
import { collection, updateDoc,doc  } from "firebase/firestore";
import AppTour from '@core/components/app-tour/AppTour.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    AppTour
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],
      knowledgeBase: [
    {
      id: 1,
      category: 'overview',
      img: require('@/assets/images/illustration/sales.svg'),
      title: 'Expert',
      desc: 'If youre familiar with Ophelio and want to get straight in, click here.',

    },
    {
      id: 2,
      category: 'financials',
      img: require('@/assets/images/illustration/marketing.svg'),
      title: 'Tutorial',
      desc: 'Learn how to use this app by clicking here.',
    },
    {
      id: 3,
      category: '/pages/faq',
      img: require('@/assets/images/illustration/api.svg'),
      title: 'FAQ',
      desc: 'You will find a FAQ here that should address your questions.',
    }]

    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    },
  },
  created() {
    this.$http.get('/kb/data/knowledge_base').then(res => { this.kb = res.data })
  },
  methods:{
    async doShit(category) {
      if(category == 1) {
          this.$router.push("/companyanalysis")
          const auth = getAuth();
          const user =  await auth.currentUser.uid;
          const docRef = doc(db, "users", user);
          await updateDoc(docRef, {tutorial: false});
      }
      if(category == 2) {
         this.$router.push("/companyanalysis")
      }
      if(category == 3) {
        this.$router.push("/pages/faq")
      }
    },
    tourStart() {
      this.$tours.vuexyTour.start()
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
